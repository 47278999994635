import { useDispatch, useSelector } from "react-redux";
import "../Message/Message.css";
import { setTogglePopUp } from "../../js/app/popupSlice";
import Loading from "../Loading";
import ResetPopUp from "./ResetPopUp";
import TargetPopUp from "./TargetPopUp";
import CategoryPopUp from "./CategoryPopUp";
import CreateSuperAdminPopUp from "./CreateSuperAdminPopUp.jsx";

const PopUp = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.popup.message);
  const toggle = useSelector((state) => state.popup.toggle);
  const toggleLoading = useSelector((state) => state.popup.toggleLoading);

  return (
    <>
      {toggle === "error" && (
        <>
          <div className="overlay">
            <div className="sent error_message">
              <div
                className="flex justify-center"
                style={{ paddingTop: "16px" }}
              >
                <p className="message-text">
                  {message ?? "something went wrong"}!
                </p>
              </div>
              <button
                className="close_button"
                onClick={() => {
                  dispatch(setTogglePopUp());
                }}
              >
                Close
              </button>
            </div>
          </div>
        </>
      )}
      {toggleLoading && <Loading />}
      {toggle === "resetPopUp" && <ResetPopUp />}
      {toggle === "targetPopUp" && <TargetPopUp />}
      {toggle === "categoryPopUp" && <CategoryPopUp />}
      {toggle === "createSuperAdmin" && <CreateSuperAdminPopUp />}
    </>
  );
};

export default PopUp;
