import ScrollToTop from "../js/ScrollToTop";
import Header from "../components/Header/Header";
import Info2 from "../components/Info2/Info2";
import Footer from "../components/Footer/Footer";
import Graph from "../components/Graph/Graph";
import { Helmet } from "react-helmet-async";

const AboutMe = () => {
  return (
    <div>
      <Helmet>
        <title>satriadega - About Me</title>
      </Helmet>
      <ScrollToTop />
      <Header />
      <div
        style={{ minHeight: "71.3vh", maxWidth: "1920px", margin: "0 auto" }}
      >
        <Info2 />
        <Graph />
      </div>
      <Footer />
    </div>
  );
};

export default AboutMe;
