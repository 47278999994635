import "./Carousel.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSearch, setToggle } from "../../js/app/searchSlice";
import { setNumberNow } from "../../js/app/sortSlice";
import { CgClose } from "react-icons/cg";

const Search = () => {
  const search = useSelector((state) => state.search.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      navigate("/logs");
      dispatch(setSearch(search));
      dispatch(setToggle(true));
      dispatch(setNumberNow(0));
    }
  };

  return (
    <form className="search_bar" style={{ alignItems: "center" }}>
      <input
        type="text"
        placeholder="Search logs!"
        onChange={(e) => {
          dispatch(setSearch(e.target.value));
          dispatch(setNumberNow(0));
          dispatch(setToggle(false));
        }}
        spellCheck="false"
        value={search}
        onKeyPress={handleKeyPress}
      ></input>
      <button
        className="button1"
        onClick={(e) => {
          e.preventDefault();
          navigate("/logs");
          dispatch(setSearch(search));
          dispatch(setToggle(true));
          dispatch(setNumberNow(0));
        }}
      ></button>
      {search && (
        <CgClose
          className="button2"
          onClick={() => {
            dispatch(setSearch(""));
            // navigate("/logs");
            dispatch(setNumberNow(0));
          }}
        ></CgClose>
      )}
    </form>
  );
};

export default Search;
