import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTogglePopUp } from "../../js/app/popupSlice";
import { createSuperAdmin } from "../../js/api/index"; // Adjust the import path as needed

const CreateSuperAdminPopUp = () => {
  const dispatch = useDispatch();
  const { toggle } = useSelector((state) => state.popup);

  // Local states for form and feedback
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    hash: "",
  });
  const [sent, setSent] = useState(false);
  const [result, setResult] = useState(false); // True for success, false for failure
  const [messageStatus, setMessageStatus] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    // Validate form data
    if (
      !formData.name ||
      !formData.email ||
      !formData.password ||
      !formData.hash
    ) {
      setResult(false);
      setMessageStatus(
        "All fields are required. Please fill out the form completely."
      );
      setSent(true);
      return;
    }

    createSuperAdmin(formData)
      .then((response) => {
        setResult(true);
        setMessageStatus("SuperAdmin has been created successfully!");
      })
      .catch((error) => {
        console.log(error.response.data);
        const errorMessage =
          error.response?.data?.message &&
          Array.isArray(error.response.data.message)
            ? error.response.data.message.join(", ")
            : error.response.data.message || "Failed to create SuperAdmin.";
        setResult(false);
        setMessageStatus(errorMessage);
      })
      .finally(() => {
        setSent(true);
      });
  };

  const handleCloseFeedback = () => {
    setSent(false);
    setResult(false);
    setMessageStatus("");
    setFormData({ name: "", email: "", password: "", hash: "" });
    dispatch(setTogglePopUp(false));
  };

  if (!toggle) return null;

  return (
    <div className="relative inline-block">
      {toggle && !sent && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          style={{ zIndex: "101" }}
        >
          <div className="p-6 sent error_message rounded shadow-lg w-full max-w-md">
            <>
              <div className="flex justify-center mb-4">
                <p className="text-center" style={{ color: "#FFF" }}>
                  Create SuperAdmin:
                </p>
              </div>
              <div className="flex flex-col mb-4 space-y-2">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="px-4 py-2 border border-gray-300 rounded w-full"
                  placeholder="Enter name"
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="px-4 py-2 border border-gray-300 rounded w-full"
                  placeholder="Enter email"
                />
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="px-4 py-2 border border-gray-300 rounded w-full"
                  placeholder="Enter password"
                />
                <input
                  type="text"
                  name="hash"
                  value={formData.hash}
                  onChange={handleInputChange}
                  className="px-4 py-2 border border-gray-300 rounded w-full"
                  placeholder="Enter hash"
                />
              </div>
              <div className="flex gap-4">
                <div className="w-1/2">
                  <button
                    onClick={() => {
                      dispatch(setTogglePopUp(false));
                    }}
                    className="px-4 py-2 bg-gray-500 text-white rounded w-full"
                  >
                    Close
                  </button>
                </div>
                <div className="w-1/2">
                  <button
                    onClick={handleSubmit}
                    className="px-4 py-2 bg-green-500 text-white rounded w-full"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
      {sent && (
        <div className="sent">
          {result ? (
            <p>SuperAdmin has been created successfully!</p>
          ) : (
            <div className="error_message">
              <p>Failed to create SuperAdmin!</p>
              <p>{messageStatus}</p>
            </div>
          )}
          <button
            className="close_button px-4 py-2 bg-gray-500 text-white rounded mt-4"
            onClick={handleCloseFeedback}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default CreateSuperAdminPopUp;
