import React, { useState, useEffect } from "react";
import "../Content.css";
import "./TimeManagement.css";
import Stopwatch from "../../../../components/Stopwatch/Stopwatch";
import { LoginTester } from "./LoginTester";
import { useDispatch, useSelector } from "react-redux";
import Activity from "../../../../js/app/users/activity";
import ActivityComponent from "../../../../components/Stopwatch/Activity";
import { useNavigate } from "react-router-dom";
import {
  setMessagePopUp,
  setToggleLoading,
  setTogglePopUp,
} from "../../../../js/app/popupSlice";
import EditTimePopUp from "../../../../components/PopUp/EditTimePopUp";

const TimeManagement = () => {
  const navigate = useNavigate();
  const nowTime = useSelector((state) => state.timeManagements.nowTime);
  const toggle = useSelector((state) => state.popup.toggle);

  const today = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = Array.from(
    { length: today.getFullYear() - 2017 },
    (_, i) => i + 2018
  );

  const getMonth = months[today.getMonth()];
  const getYear = today.getFullYear();
  let getDay = today.getDate();

  const [month, setMonth] = useState(getMonth);
  const [year, setYear] = useState(getYear);
  const [day, setDay] = useState(getDay);
  const dispatch = useDispatch();

  const calculateDays = (month) => {
    const daysInMonth = {
      January: 31,
      March: 31,
      May: 31,
      July: 31,
      August: 31,
      October: 31,
      December: 31,
      April: 30,
      June: 30,
      September: 30,
      November: 30,
      February: new Date(year, 1, 29).getMonth() === 1 ? 29 : 28,
    };
    return daysInMonth[month];
  };

  const days = Array.from({ length: calculateDays(month) }, (_, i) => i + 1);
  const populateOptions = (options) =>
    options.map((option, index) => <option key={index}>{option}</option>);

  const numMonth = months.indexOf(month) + 1;
  const inputDate = new Date(`${numMonth}/${day}/${year}`);
  const before5 = new Date();
  let dateString = `${numMonth}/${day}/${year}`;
  function generateDateString(dateInput) {
    if (!dateInput) {
      const today = new Date();
      const numMonth = String(today.getMonth() + 1).padStart(2, "0"); // Add leading zero for single-digit months
      const day = String(today.getDate()).padStart(2, "0"); // Add leading zero for single-digit days
      const year = today.getFullYear();
      return `${numMonth}/${day}/${year}`;
    }
    return dateInput;
  }

  useEffect(() => {
    dateString = `${numMonth}/${day}/${year}` ?? generateDateString(null);
    dispatch(setToggleLoading(true));
    console.log(dateString);
    Activity.fetchGuestTime(dateString, dispatch)
      .then(async () => {
        dispatch(setToggleLoading(false));
      })
      .catch((error) => {
        dispatch(setToggleLoading(false));
        dispatch(setMessagePopUp(error.response.data.message));
        dispatch(setTogglePopUp("error"));
      });
  }, [month, year, day, dispatch, nowTime]);

  return (
    <>
      {toggle === "editTimePopUp" && <EditTimePopUp dateString={dateString} />}
      <div className="content">
        <div className="flex justify-center gap-6">
          <button
            type="button"
            onClick={() => {
              dispatch(setToggleLoading(true));
              LoginTester();
              setTimeout(() => {
                dispatch(setToggleLoading(false));
                window.location.reload();
              }, 2000);
              console.log("tester done!");
            }}
            className="bg-blue-500 px-2 py-4"
            style={{ borderRadius: "5px" }}
          >
            Login as tester
          </button>
          <button
            type="button"
            onClick={() => navigate("/admin")}
            className="bg-yellow-500 px-2 py-4"
            style={{ borderRadius: "5px" }}
          >
            Login as admin
          </button>
        </div>
        <div className="content mt-20">
          <h3
            style={{
              fontWeight: "200",
              fontFamily: "Roboto",
              fontSize: "1.5rem",
            }}
            className="text-center"
          >
            Select your Date
          </h3>
          <div className="date">
            <span>
              <label>Day:</label>
              <select
                name="day"
                id="day"
                value={day}
                onChange={(e) => setDay(e.target.value)}
              >
                {populateOptions(days)}
              </select>
            </span>
            <span>
              <label>Month:</label>
              <select
                name="month"
                id="month"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              >
                {populateOptions(months)}
              </select>
            </span>
            <span>
              <label>Year:</label>
              <select
                name="year"
                id="year"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              >
                {populateOptions(years)}
              </select>
            </span>
          </div>
          {inputDate.getDate() === today.getDate() ||
          (before5.getHours() < 5 && day === before5.getDate()) ? (
            <Stopwatch dateString={`${numMonth}/${day}/${year}`} />
          ) : (
            <button
              className="currentDate"
              onClick={() => {
                setDay(before5.getDate());
                setMonth(months[today.getMonth()]);
                setYear(today.getFullYear());
              }}
            >
              Set current date
            </button>
          )}
          <ActivityComponent dateString={dateString} />
        </div>
      </div>
    </>
  );
};

export default TimeManagement;
