import "./Footer.css";
import footer1 from "../../assets/footer1.svg";

const Footer = () => {
  let getYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="bar1">
        <h6>Copyright satriadega {getYear}. All rights reserved.</h6>
      </div>
      <div className="bar2"></div>
    </div>
  );
};

export default Footer;
