import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getMessage,
  verifyLogin,
  getVisitor,
  messageAction,
  deleteMessages,
  getLogs,
} from "../../js/api";
import DeleteMessage from "../../components/Dashboard/DeleteMessage";
import "./Dashboard.css";
import AdminPanel from "../../components/AdminPanel/AdminPanel";
import { useDispatch } from "react-redux";
import { setMessagePopUp, setTogglePopUp } from "../../js/app/popupSlice";

const Dashboard = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState([]);
  const [countVisitor, setCountVisitor] = useState(0);
  const [messageType, setMessageType] = useState("unread");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [logs, setLogs] = useState([]);
  const userInfo = localStorage.getItem("userInfo");
  const arrUserInfo = userInfo.split(";");
  const [activeTab, setActiveTab] = useState("message");
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo === null || localStorage.getItem("access_token") === null) {
      navigate("/admin");
    }
    const getAMessage = async () => {
      try {
        const { result } = await getMessage();
        setMessage(result);
      } catch (err) {
        // logout();
        // navigate("/admin");
        navigate("/logs/time_management_app-2");
      }
    };
    const visitor = async () => {
      try {
        const result = await getVisitor();
        setCountVisitor(result.total);
      } catch (err) {
        // logout();
        // navigate("/admin");
        navigate("/logs/time_management_app-2");
      }
    };
    const getALogs = async () => {
      try {
        const result = await getLogs();
        const sorted = result.data.result.reverse();
        setLogs(sorted);
      } catch (err) {
        // logout();
        // navigate("/admin");
        navigate("/logs/time_management_app-2");
      }
    };
    if (messageType === "logs") {
      getALogs();
    }
    getAMessage();
    visitor();
  }, [navigate, messageType]);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 1000) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  });

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userInfo");
    navigate("/admin");
  };

  const actionHandler = (id, action) => {
    try {
      messageAction(id, { status: action });
      setMessage(message.filter((a) => a.id !== id));
    } catch (err) {
      navigate("/admin");
    }
  };

  let filteredMessage = message.filter(
    (a) => a.status === messageType.toLocaleLowerCase()
  );

  let messageMap = filteredMessage.map((i, index) => {
    let date = new Date(i.createdAt);

    const timeArray = i.time?.split(":");
    if (timeArray) {
      date.setUTCHours(timeArray[0]);
      date.setUTCMinutes(timeArray[1]);
      date.setUTCSeconds(timeArray[2]);
    }
    let localTime = `${date.getHours().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${date.getMinutes().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${date.getSeconds().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`;

    return (
      <div
        key={index}
        className={
          `${messageType === "trash" && "red-line"}` + " message flex leading-8"
        }
      >
        <div name="profile" className="text-white w40 ">
          <p className="text-4xl name break-words leading-snug">{i.name}</p>
          <p>{i.email}</p>
          <p>{`ID : ${i.id}`}</p>
          <p
            style={{ marginBottom: "20px", fontSize: "0.8rem" }}
          >{`${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()} ${localTime} `}</p>
          <div className="action">
            {(messageType === "unread" || messageType === "trash") && (
              <button
                className="archive text-sm"
                onClick={() => actionHandler(i.id, "archived")}
              >
                move to archive
              </button>
            )}
            {(messageType === "archived" || messageType === "trash") && (
              <button
                className="archive text-sm"
                onClick={() => actionHandler(i.id, "unread")}
              >
                move to unread
              </button>
            )}
            {messageType !== "trash" && (
              <button
                className="delete text-sm"
                onClick={() => actionHandler(i.id, "trash")}
              >
                delete
              </button>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div name="message" className="w60">
          <p
            className="break-words "
            style={{ color: "white", whiteSpace: "pre-line" }}
          >
            {i.message}
          </p>
        </div>
      </div>
    );
  });

  const logsMap = logs.map((i, index) => {
    const date = new Date(i.updatedAt);

    let localTime = `${date.getHours().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${date.getMinutes().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${date.getSeconds().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`;
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}-${localTime}`;
    return (
      <div key={index}>
        <p className="text-red-600 font-bold mt-4">
          {`WIB: ${formattedDate}`},
        </p>
        <p key={index} className="text-white logs-text">
          {`${i.action}`}
        </p>
      </div>
    );
  });

  const showCreateSuperAdmin = () => {
    dispatch(setTogglePopUp("createSuperAdmin"));
  };

  return (
    <div>
      <h1 className="text-white flex justify-center mt-8">
        {`This site has been visited ${countVisitor} times.`}
      </h1>
      <h1 className="text-white flex justify-center mt-2">
        {`Hello ${arrUserInfo[1]}, ${arrUserInfo[0]}`}
      </h1>
      <div className="flex justify-center mt-6 gap-4 flexResponsiveTablet_default">
        <button
          className="text-white bg-green-900 px-2 py-2 md:mx-0 mx-8"
          style={{ borderRadius: "8px" }}
          onClick={showCreateSuperAdmin}
        >
          Create Super-admin
        </button>
        <button
          className="text-white bg-green-900 px-2 py-2 md:mx-0 mx-8"
          style={{ borderRadius: "8px" }}
          onClick={() => {
            navigate("/logs/time_management_app-2");
          }}
        >
          Time Management
        </button>
        <button
          className="text-white bg-red-900 px-2 py-2 md:mx-0 mx-8"
          style={{ borderRadius: "8px" }}
          onClick={logout}
        >
          Logout
        </button>
      </div>
      <button
        className="scrollTop"
        style={{ display: showScrollButton ? "block" : "none" }}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        Scroll to top
      </button>
      <div
        name="tabs"
        className="flex justify-center text-white text-opacity-25 mt-6"
      >
        <h3
          style={{ color: activeTab === "message" ? `var(--blue)` : "" }}
          onClick={() => {
            setActiveTab("message");
          }}
          className="cursor-pointer w-20 text-center"
        >
          Message
        </h3>
        <p className="mx-4">||</p>
        <h3
          className="cursor-pointer w-20 text-center"
          onClick={() => {
            setActiveTab("adminPanel");
          }}
          style={{ color: activeTab === "adminPanel" ? `var(--blue)` : "" }}
        >
          Admin
        </h3>
      </div>
      {activeTab === "message" && (
        <div className="message_app">
          <div name="messageOption" className="flex justify-center mt-16">
            <select
              className="px-2 py-0.5"
              value={messageType}
              onChange={(e) => {
                setMessageType(e.target.value);
              }}
            >
              <option value={"unread"}>Unread</option>
              <option value={"archived"}>Archived</option>
              <option value={"trash"}>Trash</option>
              <option value={"logs"}>Logs</option>
            </select>
          </div>
          {messageType === "trash" && (
            <button className="deleteMessages" onClick={() => setIsOpen(true)}>
              Delete all messages
            </button>
          )}
          <DeleteMessage
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            deleteMessages={async () => {
              try {
                await deleteMessages(
                  message.filter((a) => a.status === "trash")
                );
                setMessage(message.filter((a) => a.status !== "trash"));
              } catch (err) {
                if (err.response.status !== 403) {
                  navigate("/admin");
                }
                if (err.response.status === 403) {
                  dispatch(setMessagePopUp(err.response.data.message));
                }
              }
            }}
          />
          <div
            name="messages"
            className="flex justify-center flex-col-reverse messages"
          >
            {message.length === 0 ? null : messageMap}
            {filteredMessage.length === 0 && messageType !== "logs" && (
              <p className="text-center text-white">No message left!</p>
            )}
          </div>

          {messageType === "logs" && (
            <div className="padding">
              <div className="logs ">{logsMap}</div>
            </div>
          )}
        </div>
      )}
      {activeTab === "adminPanel" && <AdminPanel />}
    </div>
  );
};

export default Dashboard;
