import "./Logs.css";
import logs from "./logs.json";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setSearch, setToggle } from "../../js/app/searchSlice";
import { setNumberNow } from "../../js/app/sortSlice";

const numberInPage = 2;
const paginationPages = 2;

const Logs = () => {
  const dispatch = useDispatch();
  const selects = useSelector((state) => state.sort.value);
  const search = useSelector((state) => state.search.value);
  const toggle = useSelector((state) => state.search.toggle);
  const numberNow = useSelector((state) => state.sort.numberNow);

  let sortedLogs = [...logs];
  const totalPages = Math.ceil(sortedLogs.length / numberInPage);

  // Reset to first page when search or filter changes
  useEffect(() => {
    dispatch(setNumberNow(0));
  }, [search, toggle, dispatch]);

  // Clamp page number to valid range
  useEffect(() => {
    if (totalPages > 0 && numberNow >= totalPages) {
      dispatch(setNumberNow(totalPages - 1));
    }
  }, [totalPages, numberNow, dispatch]);

  // Sorting logic
  if (selects === "newest") {
    sortedLogs.sort((a, b) => new Date(b.date) - new Date(a.date));
  } else if (selects === "oldest") {
    sortedLogs.sort((a, b) => new Date(a.date) - new Date(b.date));
  }

  // Search filtering
  const searchTerm = search.toLowerCase().split(" ");
  if (toggle) {
    sortedLogs = sortedLogs.filter((item) =>
      searchTerm.every(
        (term) =>
          item.title.toLowerCase().includes(term) ||
          item.tags.some((tag) => tag.toLowerCase().includes(term))
      )
    );
  }

  // Pagination calculations
  const currentTotalPages = Math.ceil(sortedLogs.length / numberInPage);
  const paginatedLogs = sortedLogs.slice(
    numberNow * numberInPage,
    (numberNow + 1) * numberInPage
  );

  // Calculate current tab group
  const currentTab = Math.floor(numberNow / paginationPages);
  const pages = Array.from({ length: currentTotalPages }, (_, i) => i + 1);
  const pagesToShow = pages.slice(
    currentTab * paginationPages,
    (currentTab + 1) * paginationPages
  );

  // Page change handlers
  const handlePreviousGroup = () => {
    const newPage = Math.max(numberNow - paginationPages, 0);
    dispatch(setNumberNow(newPage));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNextGroup = () => {
    const newPage = Math.min(
      numberNow + paginationPages,
      currentTotalPages - 1
    );
    dispatch(setNumberNow(newPage));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Logs mapping
  const logsMap = paginatedLogs.map((item) => {
    const slug = `${item.title.toLowerCase().replaceAll(" ", "_")}-${item.id}`;
    return (
      <nav className="log" key={item.id}>
        <div className="log_detail">
          <div className="log_heading">
            <Link to={slug}>
              <h1 className="underline decoration-3">{item.title}</h1>
            </Link>
            <p>Date added: {item.date}</p>
          </div>
          <div className="tags">
            {item.tags.map((tag, index) => (
              <div
                className="tag cursor-pointer"
                key={index}
                onClick={() => {
                  dispatch(setSearch(tag));
                  dispatch(setToggle(false));
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <p>{tag.toLowerCase()}</p>
              </div>
            ))}
          </div>
        </div>
      </nav>
    );
  });

  return (
    <div
      className="logsMap"
      style={{ minHeight: "calc(100vh - 364px)", marginBottom: "64px" }}
    >
      <p className="warning">*Click the underlined text to open the Content</p>
      {sortedLogs.length === 0 ? (
        <p style={{ color: "white", textAlign: "center" }}>No Data found!</p>
      ) : (
        logsMap
      )}

      {sortedLogs.length > 0 && (
        <div className="pagination-controls">
          <p
            className="pagination-arrow"
            onClick={handlePreviousGroup}
            disabled={currentTab === 0}
          >
            {`<<`}
          </p>
          {pagesToShow.map((pageNum) => (
            <p
              key={pageNum}
              className={pageNum - 1 === numberNow ? "btn_white" : "btn_normal"}
              onClick={() => {
                dispatch(setNumberNow(pageNum - 1));
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              {pageNum}
            </p>
          ))}
          <p
            className="pagination-arrow"
            onClick={handleNextGroup}
            disabled={
              currentTab >= Math.ceil(currentTotalPages / paginationPages) - 1
            }
          >
            {`>>`}
          </p>
        </div>
      )}
    </div>
  );
};

export default Logs;

export function loader({ params }) {
  const logId = parseInt(params.id.split("-").pop(), 10);
  return logs.find((log) => log.id === logId) || null;
}
