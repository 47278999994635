import "./Contact.css";
import Header from "../components/Header/Header";
import ScrollToTop from "../js/ScrollToTop";
import Footer from "../components/Footer/Footer";
import paperplane from "../assets/paperplane.svg";
import { useState } from "react";
import Message from "../components/Message/Message";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);
  const body = document.querySelector("body");
  if (isOpen === true) {
    body.style.overflow = "hidden";
  } else {
    body.style.overflow = "auto";
  }
  return (
    <>
      <Helmet>
        <title>satriadega - Contact</title>
      </Helmet>
      <ScrollToTop />
      <Header />

      <div className="contact_width" style={{ minHeight: "100vh" }}>
        <div
          className="wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "100px",
          }}
        >
          <div className="contact">
            <p>
              If you’re looking for something to get stuff done. Please, Send me
              a Message!
            </p>
          </div>
          <div className="message" onClick={() => setIsOpen(true)}>
            <p>Send me a Message</p>
            <img src={paperplane} alt="paperplane"></img>
          </div>
          <Message open={isOpen} onClose={() => setIsOpen(false)} />
          <div className="contact">
            <p>Or, you can send me an e-mail at</p>
            <a href="mailto:satria.pandega@gmail.com">
              satria.pandega@gmail.com
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
