import axios from "axios";
import ParamsConverter from "../paramsConverter";

export const API = axios.create({
  baseURL: "https://satriadega.online",
  // baseURL: "http://localhost:3001",
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("access_token")) {
    req.headers.access_token = localStorage.getItem("access_token");
  }

  return req;
});

export const createMessage = async (message) => {
  try {
    const result = await API.post("/tools/messages", message);
    return result;
  } catch (error) {
    return error;
  }
};

export const getMessage = async () => {
  const result = await API.get("/admins/messages");

  return result.data;
};

export const signIn = async (formLogin) => {
  const result = await API.post("/login", formLogin);
  return result;
};

export const getVisitor = async () => {
  const result = await API.get("/admins/analytics");
  return result.data;
};

export const messageAction = async (id, action) => {
  const result = await API.patch(`/admins/messages/${id}`, action);
  return result;
};

export const deleteMessages = async (messages) => {
  for (const message of messages) {
    await API.delete(`/admins/messages/${message.id}`);
  }
  return "success";
};

export const getLogs = async () => {
  const result = await API.get(`admins/messagelogs`);
  return result;
};

export const getUsers = async () => {
  const result = await API.get(`admins/users`);
  return result;
};

export const editUser = async (id, body) => {
  const result = await API.put(`admins/${id}`, body);
  return result;
};

export const getGuestTimeAPI = async (inputDate) => {
  let getGMT = new Date().getTimezoneOffset();
  getGMT = (getGMT * -1) / 60;
  let resetTime = localStorage.getItem("resetTime");
  if (resetTime === null) {
    resetTime = "05:00";
  }
  const splitter = inputDate.split("/");
  const date = new Date();
  date.setFullYear(+splitter[2]);
  date.setMonth(+splitter[0] - 1);
  date.setDate(+splitter[1]);

  const body = {
    gmt: getGMT.toString(),
    date: date.toISOString(),
    resetTime,
  };

  const result = await API.post(`users/tm/times`, body);
  return result;
};

export const postNewActivityAPI = async (body) => {
  const result = await API.post(`users/tm/times/start`, body);
  return result;
};

export const postChangeActivityAPI = async (body) => {
  const result = await API.post(`users/tm/times/change`, body);
  return result;
};

export const getIpTable = async (params) => {
  const result = await API.get(`admins/analytics`, { params });
  return result.data;
};

export const postNewTargetAPI = async (body) => {
  const result = await API.post("users/tm/targets", body);
  return result;
};

export const getTargetsAPI = async () => {
  const result = await API.get("users/tm/targets");
  return result;
};

export const patchTargetAPI = async (params, body) => {
  const formattedParams = await ParamsConverter.convert(params);
  const result = await API.patch(`users/tm/targets/${formattedParams}`, body);
  return result;
};

export const postNewCategoryAPI = async (body) => {
  const result = await API.post("users/tm/categories", body);
  return result;
};

export const getCategoriesAPI = async () => {
  const result = await API.get("users/tm/categories");
  return result;
};

export const patchCategoryAPI = async (params, body) => {
  const formattedParams = await ParamsConverter.convert(params);
  const result = await API.patch(
    `users/tm/categories/${formattedParams}`,
    body
  );
  return result;
};

export const deleteCategoryAPI = async (params) => {
  const formattedParams = await ParamsConverter.convert(params);
  const result = await API.delete(`users/tm/categories/${formattedParams}`);
  return result;
};

export const patchTimeAPI = async (params, body) => {
  const result = await API.patch(`users/tm/times/${params}`, body);
  return result;
};

export const deleteTimeAPI = async (params) => {
  const result = await API.delete(`users/tm/times/${params}`);
  return result;
};

export const getGuestTimeMonthlyAPI = async (year, month) => {
  const result = await API.get(`users/tm/timesMonthly/${year}/${month}`);
  return result.data;
};

export const createSuperAdmin = async (body) => {
  const result = await API.post("/admins/super_admin", body);
  return result.data;
};
