import "./Info2.css";
import ega from "../../assets/ega.png";
import pen from "../../assets/pen.svg";
import { Link } from "react-router-dom";

const Info2 = () => {
  function calculateDates() {
    const birthDateStr = "1996-06-10";
    const birthDate = new Date(birthDateStr);
    const today = new Date();

    let years = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      years--;
    }

    return years;
  }

  return (
    <>
      <div className="info2">
        <h1 className="heading font72">Welcome to the Web!</h1>
        <div className="w-[100%]">
          <div className="body flex py-20  justify-between " name="info2">
            <div className="ml-[5%] mr-[5%]">
              <img src={pen} alt="pen" className="pen" />
              <div
                className="flex flex-col lg:flex-row gap-8 justify-between items-center"
                style={{ margin: "0 auto" }}
              >
                <div>
                  <p style={{ width: "100%" }}>
                    Here, I work with many ideas that I can apply to many
                    different technologies. You can check it in the{" "}
                    <Link
                      to="/logs"
                      className="link"
                      style={{ textDecoration: "underline" }}
                    >
                      logs
                    </Link>{" "}
                    section.
                    <br />
                    <br />
                    I’m {calculateDates()} years old. Currently, I am
                    specializing in the Web Development field area.
                    <br />
                    <br />
                    Ega is a full-stack developer specializing in backend
                    development, with strong expertise in Java, JavaScript,
                    Golang, Python, and TypeScript. His focus is on building
                    efficient and scalable microservices using Docker
                    containerization, ensuring clean architecture and
                    maintainable code. Over the years, he has mastered database
                    management, working with MySQL, PostgreSQL, Oracle DB, and
                    MongoDB.
                    <br />
                    <br />
                    Currently, Ega is deeply involved in web development,
                    working with Spring Boot, Node.js (Express), and Nuxt
                    (TypeScript) to craft powerful applications. His experience
                    spans across data integration with Talend and optimizing
                    system performance. He continuously explores new
                    technologies, refining his approach to clean code and best
                    practices.
                    <br />
                    <br />
                    Beyond coding, Ega is a creative thinker who thrives on
                    problem-solving and innovation. With a strong foundation in
                    multiple programming languages and technologies, he is
                    always seeking new challenges and opportunities to push the
                    boundaries of development.
                  </p>
                </div>
                <img src={ega} alt="ega" className="ega"></img>
                <div className="img">
                  <img src={ega} alt="ega"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info2;
