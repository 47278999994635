import axios from "axios";
import { API } from ".";

export const sendAnalytics = async () => {
  try {
    const { data } = await axios.post("https://ipapi.co/json/");

    const userAgentInfo = getUserAgentInfo();
    await API.post(
      "/tools/analytics",
      {
        address: data.ip,
        name: `www.satriadega.com/u=${JSON.stringify(userAgentInfo)}`,
        city: data.city,
        countryName: data.country_name,
        lat: data.latitude.toString(),
        long: data.longitude.toString(),
      },
      { headers: { "ngrok-skip-browser-warning": true } }
    );
  } catch (error) {
    console.error("Error sending analytics:", error);
    return error;
  }
};

function getUserAgentInfo() {
  const userAgent = navigator.userAgent;
  let os = "Unknown OS";
  let browser = "Unknown Browser";

  // Detect OS
  if (/Windows NT/.test(userAgent)) os = "Windows";
  else if (/Mac OS X/.test(userAgent)) os = "MacOS";
  else if (/X11/.test(userAgent)) os = "UNIX";
  else if (/Linux/.test(userAgent)) os = "Linux";
  else if (/Android/.test(userAgent)) os = "Android";
  else if (/iPhone|iPad|iPod/.test(userAgent)) os = "iOS";

  // Detect Browser
  if (/Chrome/.test(userAgent) && !/Edge/.test(userAgent)) browser = "Chrome";
  else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent))
    browser = "Safari";
  else if (/Firefox/.test(userAgent)) browser = "Firefox";
  else if (/Edge|Edg/.test(userAgent)) browser = "Edge";
  else if (/MSIE|Trident/.test(userAgent)) browser = "Internet Explorer";

  return { os, browser };
}
