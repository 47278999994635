import "./Info1.css";
import { Link } from "react-router-dom";

const Info1 = () => {
  return (
    <div>
      <div className="info1">
        <h1 className="heading font72">I’m Looking for Connections</h1>

        <div className="xl:flex body xl:items-center xl:flex-row">
          <div className="xl:w-8/12">
            <p>
              I'd like to make new connections with people who are interested in
              programming, design, or other related startup business ideas.
            </p>
            <p>
              By establishing new connections, I hope we can share our
              experiences and gain a better understanding of each other's
              expertise and skills. I have three years of experience in game
              design and testing/quality assurance.
            </p>
            <p>
              I'm also interested in finding a pair-programming partner because
              I believe in the benefits of a give-and-take relationship.
              Additionally, I have an interest in IoT, security, and automation
              technology.
            </p>
            <p>Let’s Talk!</p>
          </div>
          <div className="w-full xl:w-4/12 xl:mr-[5%] xl:ml-[5%] h-full">
            <div className="flex items-center imageRes ">
              <img alt="connection img" src={require("./connections.png")} />
            </div>
          </div>
        </div>
      </div>
      <div className="cta font26 text-italic">
        <Link to="/contact" style={{ textDecoration: "none" }}>
          <p>Contact Me</p>
        </Link>
      </div>
    </div>
  );
};

export default Info1;
